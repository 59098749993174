module.exports = {
  siteTitle: "Maggie and Shelby 2021", // <title>
  shortSiteTitle: "Maggie and Shelby 2021", // <title> ending for posts and pages
  siteDescription: "Celebrating the wedding of Maggie and shelby",
  siteUrl: "https://shelbyandmaggie.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Shelby and Maggie",
  authorTwitterAccount: "",

  /* info */
  headerTitle: "Shelby and Maggie",
  headerSubTitle: "celebrating the wedding of Shelby and Maggie",

  /* manifest.json */
  manifestName: "#ILikeYourPlanner",
  manifestShortName: "wedding", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "github", url: "" },
    { name: "twitter", url: "" },
    { name: "facebook", url: "" }
  ]
};
